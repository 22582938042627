<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items" />
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h6 class="fs-16 mb-0">{{ $t('facility.facility-information') }}</h6>
        <div class="flex" style="column-gap: 8px;">
          <b-dropdown variant="primary" toggle-class="d-flex align-items-center min-h-38">
            <template #button-content>
              <div class="d-flex align-items-center" style="column-gap: 10px">
                <span>
                  {{ $t("t-import") }}
                </span>
              </div>

            </template>
            <b-dropdown-item href="#" @click="() => openUploadModal(1)">{{ $t('facility.facility-information')
            }}</b-dropdown-item>
            <b-dropdown-item href="#" @click="() => openUploadModal(2)">{{ $t('t-import-room')
            }}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown variant="primary" toggle-class="d-flex align-items-center min-h-38">
            <template #button-content>
              <div class="d-flex align-items-center" style="column-gap: 10px">
                <div v-if="state.loadingExport">
                  <Loading :width="14" :height="14" />
                </div>
                <span>
                  {{ $t('t-export') }}
                </span>
              </div>

            </template>
            <b-dropdown-item href="#" @click="handleExportFacilityInformation">{{ $t('facility.list-facilities')
            }}</b-dropdown-item>
            <b-dropdown-item href="#" @click="handleExportListFacilities">{{ $t('facility.facility-information')
            }}</b-dropdown-item>
          </b-dropdown>
          <b-button @click="navigateTo('/admin/facility/create')" variant="dark" class="waves-effect waves-light w-sm pt-2 pb-2">
            <span class="fs-12">{{ $t('t-create') }}</span>
          </b-button>
        </div>

      </div>
      <div class="table-responsive">
        <TableCommon
            ref="tableFacility"
            @onEdit="onEdit"
            @onDelete="onDelete"
            :columns="state.column"
            :endpoint="ADMIN_API_PATH.ADMIN_FACILITIES"
            :query="{role: ROLES.ADMIN}"
        />
        <b-modal v-model="state.openModal" hide-footer class="v-modal-custom" centered>
          <template #title>
            <div>
              <span class="font-weight-bold">CSV</span>{{ $t('t-csv-upload') }}
            </div>
          </template>
          <!-- <DropZone @drop.prevent="drop" @selectedFile="selectedFile" accept=".csv"
                        :allowedExtensions="['text/csv', 'application/csv']" /> -->
          <label for="upload" @dragover="e => e.preventDefault()" @drop.prevent="handleDropZone" draggable="true"
            id="dropZone" class="flex flex-column justify-content-center align-items-center modalSpacing dropZone">
            <i class="mdi mdi-cloud-upload fs-30"></i>
            <span>Drop files here or click to upload.</span>
            <span v-if="dropZoneRef">{{ dropZoneRef.name }}</span>
            <input @change="fileChange" type="file" id="upload" accept=".csv" multiple="false"
              class="hidden" />

            <!-- <button @click="downloadTemplate" class="download-button flex justify-content-center align-items-center bg-white">
              <i class="mdi mdi-download-circle fs-24"></i>
              <span>{{ $t('t-download-csv') }}</span>
            </button> -->
          </label>
          <div v-if="textRef" class="highlight">
            <p class="error-message">{{ textRef }}</p>
          </div>
        </b-modal>
      </div>
    </div>


  </Layout>
</template>

<script>
import { reactive, ref } from "vue";
import { get } from "lodash";
import * as moment from "moment/moment";
import Swal from "sweetalert2";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Loading from "@/components/Loading.vue";
import { DATE_FORMAT, ROLES, MAX_FILE_SIZE } from "@/constants";
import { facilityService, uploadService } from "@/services";
import i18n from "@/i18n";
import router from "@/router";
import * as xlsx from 'xlsx';
import TableCommon from "@/components/common/TableCommon.vue";
import {ADMIN_API_PATH} from '@/constants';

export default {
  computed: {
    ROLES() {
      return ROLES
    }
  },
  components: {
    Loading,
    Layout,
    PageHeader,
    TableCommon
  },
  methods: {
    reDataTable() {
      this.$refs.tableFacility.refreshTable()
    },
    onDelete(value) {
      this.handleDeleteFacility(value.id, value.name);
    },
    async handleDeleteFacility(facilityID, facilityName) {
      const accept = await Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('deleted_message', { message: facilityName })}</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('cancel'),
      });

      if (accept.value) {
        try {
          let res = await facilityService.deleteFacility(facilityID);
          if (res && res.deleted) {
            //refreshTable();
            this.reDataTable()
          }
        } catch (e) {
          let message = e?.response?.data?.message || '';
          await Swal.fire({
            title: "",
            text: message,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
  },

  setup() {
    const dropZoneRef = ref(null);
    const textRef = ref('');
    const state = reactive({
      query: {
        search: '',
        page: 1,
        pageSize: 10,
        role: ROLES.ADMIN
      },
      items: [
        {
          text: i18n.global.t('facility.management'),
          active: true,
        },
        {
          text: i18n.global.t('facility.facility-information'),
          active: true,
        },
      ],
      loading: false,
      hasMore: true,
      data: [],
      pagination: null,
      loadingExport: false,
      openModal: false,
      importType: 1,
      title: i18n.global.t('facility.management'),
      column: [
        {
          sortable: false,
          label: '',
          type: 'stt',
          maxWidth: 200
        },
        {
          key: 'name',
          sortable: true,
          label: i18n.global.t('facility.name'),
          maxWidth: 200
        },
        {
          key: 'facilityIdStr',
          sortable: true,
          label: i18n.global.t('facility.id-num'),
          maxWidth: 200
        },
        {
          key: 'address',
          sortable: true,
          dynamicText: (row) => `${(row['address4'] ? row['address4'] : '')}${row['address1']}${row['address2']}${row['address3']}`,
          label: i18n.global.t('facility.address'),
          maxWidth: 200
        },
        {
          key: 'playlist.name',
          sortable: true,
          label: "プレイリスト",
          maxWidth: 200
        },
        {
          key: 'phone',
          sortable: true,
          label: i18n.global.t('facility.phone'),
          maxWidth: 200
        },
        {
          key: 'lastUpdatedBy.name',
          sortable: true,
          label: i18n.global.t('facility.last-updated-staff'),
          maxWidth: 200
        },
        {
          key: 'updatedAt',
          type: 'datetime',
          sortable: true,
          label: i18n.global.t('facility.last-updated-day'),
          maxWidth: 200
        },
        {
          key: 'action',
          options: ['edit', 'delete'],
          type: 'action',
          sortable: false,
          label: ''
        }
      ]
    })

    /**
     *
     * @param {number} type
     */
    const openUploadModal = (type) => {
      state.importType = type;
      state.openModal = true;
      dropZoneRef.value = null;
      textRef.value = '';
    }

    /**
     *
     * @param {Event} event
     */
    const fileChange = (event) => {
      const { target: { files = null } } = event;
      dropZoneRef.value = files[0] || null;
      const checked = validateFile(dropZoneRef.value);
      if (checked) upload(state.importType)
    }

    /**
     *
     * @param {File} file
     * @return boolean
     */
    const validateFile = (file) => {
      const allowedExtensions = ['text/csv', 'application/csv',
        'text/comma-separated-values', 'text/anytext'];

      if (!file) return false

      if (!allowedExtensions.includes(file.type)) {
        textRef.value = i18n.global.t('msg.t-file-type-not-accepted');
        return false;
      }

      if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
        textRef.value = i18n.global.t('msg.t-exceed-filesize-limit', { size: 10 });
        return false;
      }

      return true;
    }

    /**
     *
     * @param {DragEvent} event
     */
    const handleDropZone = (event) => {
      dropZoneRef.value = event.dataTransfer.files?.[0] || null;

      const checked = validateFile(dropZoneRef.value)
      if (checked) upload(state.importType)
    }

    /**
     * 1: facility info, 2: room
     * @param {number} type
     */
    const upload = async (type) => {
      try {
        let response = null;
        if (type === 1) {
          response = await uploadService.importFacility(dropZoneRef.value);
        } else {
          response = await uploadService.importRoom(dropZoneRef.value);
        }

        if (response) {
          dropZoneRef.value = null;
          await Swal.fire({
            title: "",
            text: i18n.global.t('msg.t-import-all-records-success'),
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          state.openModal = false;
        }
        const uploadInput = document.getElementById('upload');
        uploadInput.value = null;
      } catch (e) {
        console.log('e', e);
        const uploadInput = document.getElementById('upload');
        uploadInput.value = null;
        const { message = [], statusCode = 500 } = e.data;
        if(statusCode === 500) textRef.value = i18n.global.t('t-500');
        if (message.length > 0) textRef.value = message.join('\n')
      }
    }

    const downloadTemplate = () => {
      const headerFacility = ['施設ID', 'メールアドレス', '施設名称', '姓', '名', '〒', '市区町村', '番地・号', 'ビル名', '経度', '緯度', '電話番号', '営業時間-平日', '営業時間-土日', '営業時間-祝日', '定休日-月曜日', '定休日-火曜日', '定休日-水曜日', '定休日-木曜日', '定休日-金曜日', '定休日-土曜日', '定休日-日曜日', '定休日-祝日', '定休日-Other', '料金', '会員・土日・祝日料金', '登録施設カテゴリ - 男湯サウナ', '登録施設カテゴリ - 館内全体', '登録施設カテゴリ - 女湯サウナ', '備考', 'Twitter', 'Instagram', 'Website', '施設フィルター情報'];
      const headerRoom = ['施設名称', 'ルームタイプ', '登録施設カテゴリ', 'ルーム番号', '休憩タイプ', '平均温度', '定員', '備考'];

      const sheetName = `facilities${state.importType === 1 ? '' : '_room'}`;
      const fileName = sheetName + '.csv';

      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([state.importType === 1 ? headerFacility : headerRoom]);
      xlsx.utils.book_append_sheet(wb, ws, sheetName);
      xlsx.writeFile(wb, fileName);
    }

    const formatDate = (date) => {
      return moment(date).format(DATE_FORMAT)
    }
    const navigateTo = (routerName) => {
      router.push(routerName)
    }

    const navigateUpdateFacility = (facilityID) => {
      router.push({
        name: "facility-update-management", params: {
          facilityId: facilityID
        }
      })
    }

    const handleExportListFacilities = async () => {
      const anchorFacilities = document.createElement('a');
      const anchorRooms = document.createElement('a');

      try {
        state.loadingExport = true;
        const tempArray = await Promise.all([facilityService.exportFacilities('all'), facilityService.exportFacilitiesRoom()]);
        if (tempArray.every(item => item !== null)) {
          anchorFacilities.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(tempArray[0]);
          anchorFacilities.target = '_blank';

          anchorRooms.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(tempArray[1]);
          anchorRooms.target = '_blank';

          const day = moment().format('YYYYMMDDhmmss');
          anchorFacilities.download = `施設情報_${day}`;
          anchorRooms.download = `ルーム情報_${day}`;
          await anchorFacilities.click();
          await anchorRooms.click();
        } else {
          Swal.fire({
            icon: 'error',
            title: `${i18n.global.t('t-oops')}...`,
            text: i18n.global.t('t-something-wrong'),
          })
        }
      } catch (e) {
        console.log('error')
      } finally {

        state.loadingExport = false;
      }
    }

    const handleExportFacilityInformation = async () => {
      try {
        state.loadingExport = true;
        const facilities = await facilityService.exportFacilities();
        const anchorFacilities = document.createElement('a');
        anchorFacilities.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(facilities);
        const day = moment().format('YYYYMMDDhmmss');
        anchorFacilities.download = `施設一覧_${day}`;
        anchorFacilities.target = '_blank';
        await anchorFacilities.click();
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: `${i18n.global.t('t-oops')}...`,
          text: i18n.global.t('t-something-wrong'),
        })
      } finally {
        state.loadingExport = false;
      }
    }

    const onEdit = (value) => {
      navigateUpdateFacility(value)
    }

    return {
      state,
      formatDate,
      navigateUpdateFacility,
      handleExportFacilityInformation,
      handleExportListFacilities,
      get,
      openUploadModal,
      handleDropZone,
      textRef,
      fileChange,
      downloadTemplate,
      navigateTo,
      dropZoneRef,
      ADMIN_API_PATH,
      onEdit
    }
  }
}

</script>

<style scoped>
.card::v-deep .min-h-38 {
  min-height: 38px;
}

.uploadLabel {
  padding: 8px 16px;
  border: 1px solid #ccc;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.modalSpacing {
  row-gap: 14px;
}

.download-button {
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  column-gap: 12px;
}

.highlight {
  font-size: 0.875em;
  color: #ed5e5e;
  white-space: pre-line;
}

.error-message {
  word-wrap: break-word;
  max-height: 114px;
  overflow: auto;
  word-break: break-all;
  white-space: pre-line;
}

.dropZone {
  min-height: 150px;
  border: 1px dashed #ccc;
  border-radius: 4px;
}

.dropZone>i {
  font-size: 30px;
}
</style>
